<template>
  <div id="app" class="content">
    <Navbar />
    <router-view />
    <br v-for="index in 5" :key="index" />
    <div class="wrapper">
      <Footer />
    </div>
    <vs-button v-show="showToTop" circle icon floating style="position:fixed;right: 10px;bottom:10px;" @click="toTop">
      <i class="bx bx-up-arrow-alt"></i>
    </vs-button>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  data: function () {
    return {
      showToTop: false
    }
  },
  methods: {
    scrollToTop () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const browserHeight = window.outerHeight
      if (scrollTop > browserHeight) {
        this.showToTop = true
      } else {
        this.showToTop = false
      }
    },
    toTop () {
      document.documentElement.scrollTop = 0
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  }
}
</script>

<style>
.w-full {
  width: 100% !important;
}

.w-full .vs-card__text {
  width: 100%;
}
</style>
