<template>
  <div class="footer">
    <div class="container text-center">
      <div style="width:300px;margin:0 auto; padding:20px 0;">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21029602000671" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="@/assets/baian.png" style="float:left;"/>
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">辽公网安备 21029602000671号</p>
        </a>
      </div>
      <div>
        © {{ curYear }} jussi.cn • <a href="http://beian.miit.gov.cn/" target="_blank">辽ICP备2022003241号-1</a>
      </div>
    </div>
  </div>
</template>

<script lang="js">
export default {
  name: 'Footer',
  data: function () {
    return {
      curYear: new Date().getFullYear()
    }
  },
  methods: {
    getYear: function () {
      return new Date().getFullYear()
    }
  }
}
</script>
