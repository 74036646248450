<template>
  <img :src="config.homeAvatarPath" class="cover" />
</template>

<script lang="js">
export default {
  name: 'HomeAvatar',
  data: function () {
    return {
      config: this.getConfig().config
    }
  }
}
</script>

<style scoped>
.cover {
  width: 100%;
}
</style>
